exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-salon-js": () => import("./../../../src/pages/our-salon.js" /* webpackChunkName: "component---src-pages-our-salon-js" */),
  "component---src-pages-pricelist-js": () => import("./../../../src/pages/pricelist.js" /* webpackChunkName: "component---src-pages-pricelist-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-vip-at-home-js": () => import("./../../../src/pages/vip-at-home.js" /* webpackChunkName: "component---src-pages-vip-at-home-js" */)
}

